import React, { useState } from 'react';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./footer_demo.css";

const FooterWithModal = () => {
  const [showModal, setShowModal] = useState(false);

  // List of developer names and links as a constant
  const developers = [
    { name: "Ankur Gangwar", url: "" },
    { name: "Ayush Mishra", url: "" },
    { name: "Balikeshwar Tandia", url: "" },
    { name: "Brahmprakash Gautam", url: "" },
    { name: "Jagruti Priyadarshini", url: "" },
    { name: "Kavya Sharma", url: "" },
    { name: "Nikhil Priyadarshan", url: "" },
    { name: "Pankaj Sharma", url: "" },
    { name: "Priyansu Mohanty", url: "" },
    { name: "Rajashree Sahoo", url: "" },
    { name: "Sambhav Pattanaik", url: "" },
    { name: "Subhra Sameer Dash", url: "" },
  ];

  // Toggle modal visibility
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  // Handle name click to open URL
  const handleNameClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <Container fluid id='demo_footer_container' className="footer_container bg-dark text-light py-3">
      <Row className="justify-content-center text-center">
        <Col md={6}>
          <p>© 2000-2024, Nuez Technologies Pvt Ltd</p>
          <p 
            style={{ cursor: "pointer", color: "yellow" }} 
            onClick={handleModalOpen}
          >
           {/* Developed by <span className="rainbow-text" onClick={handleModalOpen}>Nuez Tech Team</span> */}
           <span onClick={handleModalOpen}><i>Developed by Nuez Tech Team</i></span>
          </p>
        </Col>
      </Row>

      {/* Modal Component */}
      <Modal
        show={showModal}
        onHide={handleModalClose}
        centered
        size={"lg"}
        dialogClassName="custom_modal"
      >
        {/* Adjusting Header */}
        <Modal.Header style={{ padding: '0.5rem 1rem' }}>
          <Modal.Title className="w-100 text-center" style={{ color: "yellow", fontSize: '1.5rem' }}>
            Nuez Tech Team
          </Modal.Title>
          <button 
            type="button" 
            className="close" 
            style={{ color: "yellow", fontSize: "1.5rem", border: "none", background: "none" }} 
            aria-label="Close" 
            onClick={handleModalClose}
          >
            &times;
          </button>
        </Modal.Header>

        {/* Divider line */}
        <div style={{ height: "2px", backgroundColor: "yellow" }} />

        {/* Adjusting Body */}
        <Modal.Body style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <div className="container">
            <div className="row text-center">
              {/* Map over the developer names */}
              {developers.map((developer, index) => (
                <div key={index} className="col-12 col-md-6 mb-3">
                  <div
                    className="p-2 border-card rounded dev_name"  // Adjust padding here
                    style={{ 
                      backgroundColor: "black", 
                      color: "white",
                      fontSize: '0.9rem'  // Adjust font size here
                    }}
                    onClick={() => handleNameClick(developer.url)}
                  >
                    {developer.name}
                  </div>
                </div>
              ))}

              {/* Designation Section */}
              <div className="col-12 text-center mt-3">
                <span style={{ color: "yellow", fontSize: "1.2rem" }}>
                  Software Development Manager<br/> &<br/> Technical Lead
                </span>
              </div>

              {/* Project Manager Section */}
              <div className="col-12 mt-2">
                <div className="p-3 border-card rounded" style={{ 
                  backgroundColor: "black", 
                  color: "white", 
                  fontWeight: "bold" 
                }}>
                  Satya Narayan Sahoo
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        {/* Divider line */}
        <div style={{ height: "2px", backgroundColor: "yellow" }} />

        {/* Adjusting Footer */}
        <Modal.Footer style={{ padding: '0.5rem 1rem' }} className="d-flex justify-content-center custom_modal_footer">
          <Button className="close-button" onClick={handleModalClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default FooterWithModal;
