import './App.css';
import Home from './pages/Home/Home';
import Demo from "./pages/demo/demo";
import HeaderNav from "./components/header/header";
import { HashRouter, Route, Routes } from "react-router-dom";
import { useRef } from 'react';

function App() {
  // Create refs for each section
  const heroRef = useRef(null);
  const featureRef = useRef(null);
  const howItWorksRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <HashRouter>
      <HeaderNav
        heroRef={heroRef}
        howItWorksRef={howItWorksRef}
        featureRef={featureRef}
        aboutRef={aboutRef}
        contactRef={contactRef}
      />
      <Routes>
        <Route path='/' element={
          <Home
          heroRef={heroRef}
          featureRef={featureRef}
          howItWorksRef={howItWorksRef}
          aboutRef={aboutRef}
          contactRef={contactRef}
          />
        } />
        <Route path='demo' element={<Demo />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
