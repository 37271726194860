import React from 'react'
 import "./footer.css"
 import { FaInstagram } from "react-icons/fa6";
 import { FaFacebookSquare } from "react-icons/fa";
 import { FaLinkedin } from "react-icons/fa";


const footer = () => {
  return (
    <div className='footer_container'>
        {/* <div className="footer_icon_container">
        <FaInstagram />
<FaFacebookSquare />
<FaLinkedin />
        </div> */}
        <div className="footer_text_container">
            <p>© 2000-2024, Nuez Technologies Pvt Ltd <br /> All right reserved. </p>
        </div>
    
    </div>
  )
}

export default footer
