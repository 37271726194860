import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from "../../image/nuez_logo____.jpg";
import "./header.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function HeaderNav({ heroRef, howItWorksRef, featureRef, aboutRef, contactRef }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isNavExpanded, setIsNavExpanded] = useState(false); // State to manage navbar toggle

    // Function to handle navigation and scroll to a specific section
    const handleNavClick = (ref, sectionPath = "/") => {
        if (location.pathname !== "/") {
            // Navigate to home if not already there
            navigate(sectionPath);
        }
        // After navigation, scroll to the section if a ref is provided
        setTimeout(() => {
            if (ref) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
                // Scroll to the top if no ref is provided
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }, 100); // Small delay to ensure the navigation happens first

        // Close the navbar if it is expanded
        if (isNavExpanded) {
            setIsNavExpanded(false);
        }
    };

    return (
        <Navbar
            variant='dark'
            sticky='top'
            collapseOnSelect
            expand="lg"
            className="bg-body-tertiary custom_nav"
            expanded={isNavExpanded} // Control expanded state with a prop
        >
            <Container className='custom_nav_container'>
                <Navbar.Brand onClick={() => handleNavClick(null)} style={{ cursor: 'pointer' }}>
                    <img src={logo} className="logo" alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle
                    onClick={() => setIsNavExpanded(!isNavExpanded)} // Toggle the state
                    aria-controls="responsive-navbar-nav"
                />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto gap-3 text-center">
                        <Nav.Link onClick={() => handleNavClick(null)} className='text-white'>
                            Home
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavClick(howItWorksRef)} className='text-white'>
                            How it Works
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavClick(featureRef)} className='text-white'>
                            Features
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavClick(aboutRef)} className='text-white'>
                            About
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavClick(contactRef)} className='text-white'>
                            Contact
                        </Nav.Link>
                        <a href="https://smart.nuez.tech" target="_blank" rel="noopener noreferrer" className='nav-link text-white'>
                            Dashboard
                        </a>
                        <Nav.Link onClick={() => {navigate("/demo"); handleNavClick(null, "/demo")}} className='text-white'>
                            Demo
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default HeaderNav;
