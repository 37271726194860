import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import videoData from '../../data/videoData.json';
import "./demo.css";
import Footer from '../../components/footer_demo/footer_demo'
import temp_style from './coming_soon.module.css';

const DEMO_TAB_1_TITILE = "Software Demos";
const DEMO_TAB_2_TITLE = "Hardware Demos";
const NO_VIDEO_TEXT = "No video available";

const Demo = () => {
  const [hideTabs, setHideTabs] = useState(false);
  const [activeKey, setActiveKey] = useState('tab_1');

  useEffect(() => {
    const handleScroll = () => {
      const bottomOffset = 50; // Adjust threshold as needed
      const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - bottomOffset;
      setHideTabs(isAtBottom);
    };
    

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handlePlay = () => {
    setHideTabs(true);
  };

  const handlePause = () => {
    setHideTabs(false); 
  };

  return (
    <>
      <Tabs
        defaultActiveKey="tab_1"
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
        transition={true}
        id="demo_tabs"
        className={`justify-content-center mt-4 mb-3 gap-3 custom_tab ${hideTabs && activeKey === 'tab_1' ? 'hide' : ''}`}
        variant='pills'
      >
        <Tab className='custom_tab_button' eventKey="tab_1" title={DEMO_TAB_1_TITILE}>
          <DemoVideo videoData={videoData.SOFTWARE_DEMOS_VIDEO_LIST} onPlay={handlePlay} onPause={handlePause} />
        </Tab>
        <Tab className='custom_tab_button' eventKey="tab_2" title={DEMO_TAB_2_TITLE}>
          <NoVideo />
        </Tab>
      </Tabs>
      <Footer />
    </>
  );
};

export default Demo;

const DemoVideo = ({ videoData, onPlay, onPause }) => {
  return (
    <div className='demo_container' id='demo'>
      {videoData.map((video, index) => (
        <div className="demo_video_card" key={index}>
          <ReactPlayer
            controls
            url={video.url}
            className="video"
            height="200px"
            width="300px"
            onPlay={onPlay}
            onPause={onPause}
          />
          <div className="demo_video_text">{video.text}</div>
        </div>
      ))}
    </div>
  )
}

const NoVideo = () => {
  return (
    <>
      <div className={temp_style.container}>
        <div className={temp_style.text}>
          {NO_VIDEO_TEXT}
        </div>
      </div>
    </>
  )
}
