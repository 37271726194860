import React from 'react'
import "./contactus.css"
import { HiOutlineMail } from "react-icons/hi";
import { FaRegAddressCard } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";

const contactus = () => {
  return (


    <div className='contact_container' id='contact'>
      <div className="contact_header_container">
        <div className='contact_header'>
          <h2> Let’s Connect </h2>
        </div>
        <div className="contact_header_text">
          <p>We would love to hear from you! Reach out to us through any of the following ways:</p>
        </div>
      </div>

      <div className="contact_info_container">
        <div className="contact_info">
          <div className="contact_info_icon">
          <HiOutlineMail   size={30}/>
          </div>
          <div className="contact_info_header">Email</div>
          <div className='contact_info_text'>
          info@nueztpl.co.in
          </div>
        </div>

        <div className="contact_info">
          <div className="contact_info_icon">
          <FaRegAddressCard  size={30} />
          </div>
          <div className="contact_info_header">Address</div>
          <div className='contact_info_text'>
          No A-207, B-205, B-206, KSSIDC Complex, Block-II,
          Electronic City, Hosur Road, Begnaluru - 560 100.
          </div>
        </div>

        <div className="contact_info">
          <div className="contact_info_icon">
          <FaPhoneAlt   size={30} />
          </div>
          <div className="contact_info_header">Phone</div>
          <div className='contact_info_text'>
          96325-69771
          </div>
        </div>
      </div>
    </div>
  )
}

export default contactus
