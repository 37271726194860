import React from 'react'
import Button from 'react-bootstrap/Button';
import style from './Hero.module.css';
import MySVG from '../../image/hero_image.png';   //this is not a svg hehe

const HERO_CONTACT_BUTTON_TEXT = "View demo"

const Hero = () => {
  return (
    <>
      <div className={style.hero_container} id='home'>
        <div className={style.hero_text_container}>
          <div className={style.hero_text}>
            <h1 className={style.hero_h1}>Smart Water Meter Module</h1>
            <h2 className={style.hero_h2}><b>Welcome to Nuez Technologies!</b> We’re excited to introduce you to our Smart Water Metering Module, designed to make monitoring your water usage effortless. With our smart meters, you can easily access your water consumption data in real-time from anywhere, helping you manage usage and reduce waste. Enjoy user-friendly features that allow you to track your water usage on the go and gain valuable insights into your usage pattern. Our mission is to help you make informed decisions that promote water conservation and save you money. Together, let’s create a more sustainable future, one drop at a time!</h2>
          </div>
          <a href="#/demo">
            <Button variant="outline-primary" className={style.hero_button}  >{HERO_CONTACT_BUTTON_TEXT} </Button>
          </a>
        </div>
        <div className={style.hero_image_container}>
          <img className={style.hero_image} src={MySVG} alt="Hero SVG" />
        </div>
      </div>
    </>
  )
}

export default Hero