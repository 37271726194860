import React from 'react';
import { motion } from 'framer-motion';
import image1 from "../../image/work_img.jpeg";
import image2 from "../../image/module_img.jpeg";
import "./W_swm.css";

const W_swm = () => {


  const textVariant = {
    hidden: { opacity: 0, y: 50 }, 
    visible: { opacity: 1, y: 0 },
  };

  return (
    <>
      <div className="w_swm_container">
        {/* First section */}
    <div className="w_swm_container" id='HowitWork'>
        <div className="w_swm_container1">
          <div className='w_swm_text_container'>
            <motion.h1
              className="w_swm_h1"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              transition={{ duration: 0.8 }}
              variants={textVariant}
            >
             
What is the Smart Water Meter Module?

            </motion.h1>

            <motion.p
              className="w_swm_p"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.2 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              variants={textVariant}
            >
The Smart Water Meter Module is a user-friendly device that helps you monitor your water usage effortlessly. It works by collecting data on how much water you consume and sending this information securely to the internet. This means you can easily access your water usage data anytime and anywhere, using a simple app on your phone or computer.<br />
With our smart meter, you can see your water consumption in real-time, allowing you to understand your habits and make informed decisions about your water use. This not only helps you become more aware of your consumption but also empowers you to save both water and money.<br />
The module is designed to be easy for anyone to use, whether you're a homeowner or a business owner. By eliminating the need for manual meter readings, it takes the hassle out of tracking water usage. Plus, its smart features ensure that you’re always in control of your water management, contributing to a more sustainable future.<br />

            </motion.p>
          </div>

          <div className="w_swm_img_container">
            <img src={image2} className='w_swm_img' alt="Smart Water Module" />
          </div>
        </div>

        {/* Second section */}
        <div className="w_swm_container2">
          <div className='w_swm_text_container'>
            <motion.h1
              className="w_swm_h1"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.2 }}
              transition={{ duration: 0.8 }}
              variants={textVariant}
            >
              How does it work?
            </motion.h1>

            <motion.p
              className="w_swm_p"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.2 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              variants={textVariant}
            >
      The Smart Water Meter Module is designed to make monitoring your water usage simple and efficient. It starts with the installation of the smart water meter at your water entry point, where it measures the flow of water in real-time. As you use water, the meter continuously records your consumption, capturing every drop. This data is securely transmitted to the cloud through our reliable gateway device. Even if your internet connection is interrupted, the meter stores the data locally and syncs it with the cloud once the connection is restored. You can easily access your water usage data through a user-friendly app on your smartphone or computer, allowing you to view real-time consumption, track historical data, and gain insights into your water habits. Additionally, the system regularly checks its own health and battery status, sending you alerts if any issues arise, ensuring that you can address them promptly. This seamless process empowers you to manage your water consumption effectively, helping you save both water and money effortlessly.
            </motion.p>
          </div>

          <div className="w_swm_img_container">
            <img src={image1} className='w_swm_img' alt="How it works" />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default W_swm;
