import React from 'react';
import { motion } from 'framer-motion';
import './about.css';
import Aboutimage from '../../image/aboutus.png';
import { SiTransmission } from 'react-icons/si';
import { TiEye } from 'react-icons/ti';
import { FaSortAmountUpAlt } from 'react-icons/fa';
import { style } from 'react'

const aboutus = () => {
   
    const textVariant = {
        hidden: { opacity: 0, x: 100 }, 
        visible: { opacity: 1, x: 0 }, 
    };

    return (
        <div className='aboutus_container' id='about'>
            <div className='aboutus_left'>
                <h1 className='aboutus_header'>
                    About Us
                </h1>
                <div className='aboutus_img'>
                    <img src={Aboutimage} className='img1' alt="About Us" />
                </div>
            </div>

            <div className='aboutus_right'>
                {/* Our Mission */}
                <motion.div
                    className='aboutus_content_container'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.2 }}
                    transition={{ duration: 0.8 }}
                    variants={textVariant}
                >
                    <div className='aboutus_icon_container'>
                        <SiTransmission size={20} />
                    </div>
                    <div className='aboutus_text_container'>
                        <h1 className='aboutus_content_header'>Our Mission:</h1>
                        <div className='aboutus_content'>
                            At Nuez Technologies Pvt Ltd, we revolutionize water management with our Smart Water Meter, providing real-time data to help utilities, businesses, and consumers optimize water use and reduce environmental impact.
                        </div>
                    </div>
                </motion.div>

                {/* Our Values */}
                <motion.div
                    className='aboutus_content_container'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.2 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    variants={textVariant}
                >
                    <div className='aboutus_icon_container'>
                        <FaSortAmountUpAlt size={30} />
                    </div>
                    <div className='aboutus_text_container'>
                        <h1 className='aboutus_content_header'>Our Values:</h1>
                        <div className='aboutus_content'>
                            Our company drives innovation in water management, prioritizing sustainability, reliability, and customer focus to deliver advanced solutions that reduce waste and provide consistent, high-value performance and service.
                        </div>
                    </div>
                </motion.div>

                {/* Our Vision */}
                <motion.div
                    className='aboutus_content_container'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.2 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    variants={textVariant}
                >
                    <div className='aboutus_icon_container'>
                        <TiEye size={30} />
                    </div>
                    <div className='aboutus_text_container'>
                        <h1 className='aboutus_content_header'>Our Vision:</h1>
                        <div className='aboutus_content'>
                            To be the leading provider of intelligent water management solutions globally, fostering a more efficient and sustainable world.
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default aboutus;
