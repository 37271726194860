import React from 'react';
import { motion } from 'framer-motion';
import './feature.css';
import featured_3 from "../../image/f_img_3.jpeg";
import featured_4 from "../../image/f_img_4.jpeg";
import featured_5 from "../../image/f_img_5.jpeg";
import featured_6 from "../../image/f_img_6.jpeg";
import featured_7 from "../../image/f_img_7.jpeg";
import featured_8 from "../../image/img_swm.jpg";
import featured_9 from "../../image/f_img_9.jpeg";

const Featured = () => {

  const textVariant = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const features = [
    {
      heading: ' Cloud-Connected Water Monitoring',
      description: 'Our Smart Water Meter Module captures water consumption data and securely sends it to the cloud via a gateway device. This enables users to access data remotely through web and mobile applications, eliminating the need for manual meter readings.',
      benefits: ['Access real-time and historical water consumption data from anywhere.',
        'Generate automated reports for water usage patterns and trends.',
        'Improve transparency and accountability in water management.',

      ],
      image: featured_3
    },
    {
      heading: 'Web and Mobile Monitoring',
      description: ' The intuitive user interface on both web and mobile platforms allows easy access to water usage data. Users can view real-time consumption data, monitor the status of devices, and control various aspects of the system remotely.',
      benefits: ['Real-time monitoring through a secure web and mobile app.',
        'Easy access to device status, battery life, and health indicators.',
        'Full control of maintenance mode and firmware updates.',


      ],
      image: featured_4
    },
    {
      heading: 'Device Health and Heartbeat Monitoring',
      description: ' The Smart Water Meter Module sends regular heartbeat signals to ensure continuous monitoring of the device’s health. This includes real-time status updates on battery life, communication quality, and general performance.',
      benefits: ['Receive notifications if a device fails or has connectivity issues.',
        'Monitor battery voltage and ensure timely replacement before power runs out.',
        'Minimize downtime with proactive device health checks.',


      ],
      image: featured_8
    },
    {
      heading: 'Reliable Gateway Device',
      description: 'Our gateway device ensures reliable data transmission even under challenging conditions such as intermittent internet connectivity, power outages, or device restarts. It stores data locally and syncs with the cloud once the connection is restored.',
      benefits: ['Continuously captures water usage data even when the internet is down.',
        'Self-healing mechanism to automatically resume after server or gateway restarts.',
        'Easy recovery from network interruptions without data loss.',


      ],

      image: featured_5
    },
    {
      heading: 'Maintenance Mode & OTA Firmware Upgrades',
      description: 'Admins can switch the Smart Water Meter Module into maintenance mode for tasks such as battery replacement or perform Over-the-Air (OTA) firmware upgrades, ensuring that devices stay updated without the need for on-site visits.',
      benefits: ['Perform upgrades and maintenance remotely, reducing the need for field visits.',
        'Keep the system up-to-date with the latest features and security enhancements.',
        'Customizable scheduling for maintenance tasks to avoid disruption.',


      ],

      image: featured_9
    },
    {
      heading: 'Super Admin Control',
      description: ' The Super Admin role provides comprehensive control over the entire system, including the ability to manage any site, take over administrative functions, and update software running on the cloud server remotely.',
      benefits: ['Centralized control over multiple sites and devices.',
        'Ability to troubleshoot or update settings for all users and admins.',
        'Enhance operational efficiency with remote software updates.',


      ],
      image: featured_6
    },
    {
      heading: 'Battery Saving Mode',
      description: 'To conserve battery power, the Smart Water Meter Module is kept in deep sleep mode most of the time, waking up at preset intervals (customizable) to perform essential tasks such as water consumption measurement and data transmission.',
      benefits: ['Prolongs battery life, reducing the frequency of battery replacements.',
        'Customizable wake-up intervals to suit varying usage needs.',
        'Minimize power consumption without sacrificing performance.',


      ],
      image: featured_7
    }
  ];


  return (
    <div className='feature_container'>
      <div className='feature_container_header'>
        <h2 className='feature_h2'>Smart Water Meter Features for Effortless Water Management</h2>
      </div>

      {features.map((feature, index) => (
        <div className={`feature_item ${index % 2 === 0 ? 'even' : 'odd'}`} key={index}>
          <div className='feature_image'>
            <img src={feature.image} alt={feature.heading} />
          </div>
          <div className='feature_text'>
            <motion.h3
              className='feature_heading'
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.2 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              variants={textVariant}
            >
              {feature.heading}
            </motion.h3>
            <motion.p
              className='feature_description'
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.2 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              variants={textVariant}
            >
              {feature.description}
            </motion.p>
            <motion.ul
              className='feature_benefits'
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.2 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              variants={textVariant}
            >
              {feature.benefits.map((benefit, i) => (
                <li key={i}>{benefit}</li>
              ))}
            </motion.ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Featured;
