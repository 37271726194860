import React, { forwardRef } from 'react';
import Hero from "../../components/hero/Hero";
import Wswm from "../../components/what_swm/W_swm";
import About from "../../components/about/about";
import Contactus from '../../components/contactus/contactus';
import Footer from "../../components/footer/footer";
import Feature from "../../components/featured/featured";

const Home = forwardRef(({ heroRef, featureRef, howItWorksRef, aboutRef, contactRef }, ref) => {
  return (
    <div>
      <div ref={heroRef}><Hero /></div>
      <div ref={featureRef}><Feature /></div>
      <div ref={howItWorksRef}><Wswm /></div>
      <div ref={aboutRef}><About /></div>
      <div ref={contactRef}><Contactus /></div>
      <Footer />
    </div>
  );
});

export default Home;
